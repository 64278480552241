import * as firebase from "firebase/app";

import React, { Component } from "react";

import Footer from "../Footer";
// import Meta from "../../helpers/meta";
// import Nav from "../Nav";
import PromoSlider from "./PromoSlider";
import { Redirect } from "react-router";
import RestaurantList from "./RestaurantList";
import { connect } from "react-redux";
import { getPromoSlides } from "../../../services/promoSlider/actions";

import messaging from "../../../init-fcm";
import { saveNotificationToken } from "../../../services/notification/actions";
import { getSingleLanguageData } from "../../../services/languages/actions";
import { getUserNotifications } from "../../../services/alert/actions";
import {
  resetInfo,
  resetItems,
  //resetBackup,
} from "../../../services/items/actions";

import { Link } from "react-router-dom";
// import moment from "moment";

class Home extends Component {
  static contextTypes = {
    router: () => null,
  };

  async componentDidMount() {
    this.props.resetItems();
    this.props.resetInfo();
    //this.props.resetBackup();

    const { user } = this.props;

    //if currentLocation doesnt exists in localstorage then redirect the user to firstscreen
    //else make API calls
    if (localStorage.getItem("userSetAddress") !== null) {
      // this.context.router.history.push("/search-location");
      // console.log("Redirect to search location");
      // return <Redirect to="/search-location" />;
      if (localStorage.getItem("showPromoSlider") === "true") {
        const userSetAddress = JSON.parse(
          localStorage.getItem("userSetAddress")
        );

        const { user } = this.props;

        if (user && user.data) {
          this.props.getPromoSlides(
            userSetAddress.lat,
            userSetAddress.lng,
            user.data.auth_token,
            user.data.id
          );
        }
      }

      const { user } = this.props;

      if (user.success) {
        this.props.getUserNotifications(user.data.id, user.data.auth_token);
      }
    } else {
      //call to promoSlider API to fetch the slides
    }

    if (user.success) {
      if (localStorage.getItem("enablePushNotification") === "true") {
        if (firebase.messaging.isSupported()) {
          // const today = moment().toDate();

          // console.log("TODAY", today);
          // const lastSavedNotificationToken = moment(localStorage.getItem("lastSavedNotificationToken"));
          // const days = moment(today).diff(lastSavedNotificationToken, "days");

          // console.log("DAYS", days);

          // const callForNotificationToken = isNaN(days) || days >= 5;

          // console.log(callForNotificationToken);
          // if (callForNotificationToken) {
          let handler = this.props.saveNotificationToken;
          messaging
            .requestPermission()
            .then(async function() {
              const push_token = await messaging.getToken();
              handler(push_token, user.data.id, user.data.auth_token);
              // localStorage.setItem("lastSavedNotificationToken", today);
            })
            .catch(function(err) {
              console.log("Unable to get permission to notify.", err);
            });
          // }
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.languages !== nextProps.languages) {
      if (localStorage.getItem("userPreferedLanguage")) {
        this.props.getSingleLanguageData(
          localStorage.getItem("userPreferedLanguage")
        );
      } else {
        if (nextProps.languages.length) {
          // console.log("Fetching Translation Data...");
          const id = nextProps.languages.filter(
            (lang) => lang.is_default === 1
          )[0].id;
          this.props.getSingleLanguageData(id);
        }
      }
    }
  }

  componentWillUnmount() {
    // navigator.serviceWorker.removeEventListener("message", message => console.log(message));
  }

  render() {
    if (window.innerWidth > 768) {
      return <Redirect to="/" />;
    }

    if (localStorage.getItem("userSetAddress") === null) {
      // this.context.router.history.push("/search-location");
      // console.log("Redirect to search location");
      //return <Redirect to="/search-location" />;
      return <Redirect to="/" />;
    }

    const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
    if (
      Object.keys(userSetAddress).length === 0 &&
      userSetAddress.constructor === Object
    ) {
      //return <Redirect to="/search-location" />;
      return <Redirect to="/" />;
    }

    const { user, promo_slides } = this.props;
    const hasStoreHead = localStorage.getItem("storeHead") !== "null";

    // console.log(promo_slides.mainSlides.length);

    return (
      <React.Fragment>
        <div className="height-100-percent bg-white mb-50">
          {/* <Nav
            logo={true}
            active_nearme={true}
            disable_back_button={true}
            history={history}
            loggedin={user.success}
          /> */}

          {/* Passing slides as props to PromoSlider */}
          {promo_slides &&
            localStorage.getItem("showPromoSlider") === "true" && (
              <React.Fragment>
                {promo_slides &&
                  promo_slides.mainSlides &&
                  promo_slides.mainSlides.length > 0 && (
                    <PromoSlider
                      slides={promo_slides.mainSlides}
                      size={promo_slides.mainSlides[0]["promo_slider"]["size"]}
                      isCartPage={false}
                    />
                  )}
              </React.Fragment>
            )}
          {hasStoreHead ? (
            <div style={{ position: "relative", marginBottom: "10px" }}>
              <div style={{ position: "relative" }}>
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={`/assets/img/logos/${localStorage.getItem("storeHead")}`}
                />{" "}
                <div className="search-address">
                  <div className="item-store-search">
                    {localStorage.getItem("mockSearchOnHomepage") ===
                      "true" && (
                      <Link
                        to="explore"
                        className="anchor-link"
                        style={{ width: "100%" }}
                      >
                        <div className="mock-search-block px-15">
                          {/* pb-10 */}
                          <div
                            className="d-flex justify-content-between"
                            style={{ padding: "10px" }}
                          >
                            <div>
                              <span>
                                {localStorage.getItem("mockSearchPlaceholder")}
                              </span>
                            </div>
                            <div>
                              <i className="si si-magnifier" />
                            </div>
                          </div>
                        </div>
                      </Link>
                    )}
                  </div>
                  {user && user.data && user.data.default_address ? (
                    <div className="d-flex justify-content-center align-items-center row-flex">
                      <div
                        style={{
                          width: "90%",
                          color: localStorage.getItem("headColor"),
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <div style={{ width: "90%" }}>
                          <p className="home-deliver-to">
                            <span>
                              <strong>Deliver To - </strong>{" "}
                            </span>
                            <Link to="my-addresses" className="anchor-link">
                              <span>
                                {user.data.default_address.tag !== null && (
                                  <strong
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {user.data.default_address.tag} -
                                  </strong>
                                )}
                              </span>{" "}
                              "<span>{user.data.default_address.address}</span>"
                            </Link>
                          </p>
                        </div>
                        <Link to="my-addresses" className="anchor-link">
                          <span>
                            <i className="si si-arrow-down" />
                          </span>
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div className="home-page-header">
              <div className="img-content">
                <div className="logo-img-container">
                  <img
                    src={`/assets/img/logos/${localStorage.getItem(
                      "storeLogo"
                    )}`}
                    alt={localStorage.getItem("storeLogo")}
                    className="logo-e"
                  />
                </div>
                <div className="header-content">
                  <p className="header-data">Elevate Your Sports</p>
                  <p className="header-data" style={{ fontSize: "23px" }}>
                    Order Now!!!
                  </p>
                </div>
              </div>

              <div className="item-store-search">
                {localStorage.getItem("mockSearchOnHomepage") === "true" && (
                  <Link
                    to="explore"
                    className="anchor-link"
                    style={{ width: "100%" }}
                  >
                    <div className="mock-search-block px-15">
                      <div
                        className="d-flex justify-content-between"
                        style={{ padding: "10px" }}
                      >
                        <div>
                          <span>
                            {localStorage.getItem("mockSearchPlaceholder")}
                          </span>
                        </div>
                        <div>
                          <i className="si si-magnifier" />
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
              {user && user.data && user.data.default_address ? (
                <div className="d-flex justify-content-center align-items-center row-flex">
                  <div
                    style={{
                      width: "90%",
                      color: localStorage.getItem("headColor"),
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div style={{ width: "90%" }}>
                      <p className="home-deliver-to">
                        <span>
                          <strong>Deliver To - </strong>{" "}
                        </span>
                        <Link to="my-addresses" className="anchor-link">
                          <span>
                            {user.data.default_address.tag !== null && (
                              <strong style={{ textTransform: "uppercase" }}>
                                {user.data.default_address.tag} -
                              </strong>
                            )}
                          </span>{" "}
                          "<span>{user.data.default_address.address}</span>"
                        </Link>
                      </p>
                    </div>
                    <Link to="my-addresses" className="anchor-link">
                      <span style={{ color: "black" }}>
                        <i className="si si-arrow-down" />
                      </span>
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          )}
          {localStorage.getItem("customHomeMessage") !== "<p><br></p>" &&
            localStorage.getItem("customHomeMessage") !== "null" &&
            (localStorage.getItem("customHomeMessage") !== "" && (
              <div
                style={{ position: "relative", background: "#f8f9fa" }}
                dangerouslySetInnerHTML={{
                  __html: localStorage.getItem("customHomeMessage"),
                }}
              />
            ))}
          <RestaurantList user={user} slides={promo_slides.otherSlides} />
          <Footer active_nearme={true} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  promo_slides: state.promo_slides.promo_slides,
  user: state.user.user,
  locations: state.locations.locations,
  languages: state.languages.languages,
});

export default connect(
  mapStateToProps,
  {
    getPromoSlides,
    saveNotificationToken,
    getSingleLanguageData,
    getUserNotifications,
    resetInfo,
    resetItems,
    //resetBackup,
  }
)(Home);
